import React, { useState, useEffect } from 'react';
import { navigate } from 'gatsby';
import StructuredText from 'src/components/base/structuredText';
import { useGlobalState } from 'src/components/base/globalState';
import FormControl from 'src/components/base/formControl';
import { submitForm } from 'src/util';

const BlockWaitlistForm = ({ buttonText, preFormContent, afterFormContent, onComplete }) => {
  const { state, paymentType } = useGlobalState();
  const [name, setName] = useState();
  const [email, setEmail] = useState();
  const [zipCode, setZipCode] = useState();

  // If the user hasn't gone through the enrollment flow yet, redirect them to do so.
  useEffect(() => {
    if (!state || !paymentType) {
      navigate('/eligibility');
    }
  }, [state, paymentType]);

  return (
    <div>
      <form
        id="preregister"
        action={process.env.GATSBY_FORM_HANDLER}
        onSubmit={(evt) => submitForm(evt, [], onComplete)}
      >
        <input type="hidden" name="id" value="preregister" />

        <StructuredText data={preFormContent} />

        <FormControl name="name" label="Name" value={name} setValue={setName} required />
        <FormControl
          name="email"
          type="email"
          label="Email"
          value={email}
          setValue={setEmail}
          required
        />
        <FormControl
          name="zipCode"
          type="zip"
          label="Zip Code"
          value={zipCode}
          setValue={setZipCode}
          required
        />

        <input type="hidden" name="state" value={state} />
        <input type="hidden" name="paymentType" value={paymentType} />

        <StructuredText data={afterFormContent} />

        <div>
          <button type="submit" className="button">
            {buttonText}
          </button>
        </div>
      </form>
    </div>
  );
};

export default BlockWaitlistForm;
