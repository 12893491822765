import React from 'react';
import { graphql, navigate } from 'gatsby';
import Layout from 'src/components/layouts/default';
import StructuredText from 'src/components/base/structuredText';
import BlockWaitlistForm from 'src/components/blocks/blockWaitlistForm';
import * as classes from './waitlist.module.scss';

export const query = graphql`
  query {
    datoCmsStart {
      waitlistTitle
      waitlistLede {
        blocks
        links
        value
      }
      waitlistAfterFormContent {
        blocks
        links
        value
      }
      waitlistButtonText
      waitlistOtherServicesHeader
      waitlistOtherServicesContent {
        blocks
        links
        value
      }
      waitlistCompleteContent {
        blocks
        links
        value
      }
    }
  }
`;

const WaitlistPage = ({ data, location }) => (
  <Layout location={location} pageTitle={data.datoCmsStart.waitlistTitle}>
     <div className={classes.body}>
      <BlockWaitlistForm
        preFormContent={data.datoCmsStart.waitlistLede}
        afterFormContent={data.datoCmsStart.waitlistAfterFormContent}
        buttonText={data.datoCmsStart.waitlistButtonText}
        onComplete={() => navigate(
          '/complete',
          {
            state: {
              pageTitle: data.datoCmsStart.waitlistTitle,
              body: data.datoCmsStart.waitlistCompleteContent,
            },
          }
        )}
      />
    </div>
    <div className={classes.otherServices}>
      <h2>{data.datoCmsStart.waitlistOtherServicesHeader}</h2>
      <StructuredText data={data.datoCmsStart.waitlistOtherServicesContent} />
    </div>
  </Layout>
);

export default WaitlistPage;
